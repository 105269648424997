import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "antd/dist/antd.css";
import { Steps, Button } from "antd";
import NuovoPalinsesto1 from "./nuovo-palinsesto-1";
import NuovoPalinsesto2 from "./nuovo-palinsesto-2";
import NuovoPalinsesto3 from "./nuovo-palinsesto-3";

export let palinsestoData = {
  palinsesto: "",
  uuid: "",
  categoria: 0,
  template: 0,
  items: [],
  eventi: [],
};

const { Step } = Steps;

const steps = [
  {
    title: "Informazioni generali",
    content: <NuovoPalinsesto1 />,
    disabled: false,
  },
  {
    title: "Gestione brand",
    content: <NuovoPalinsesto2 />,
    disabled: false,
  },
  {
    title: "Assegnazione template",
    content: <NuovoPalinsesto3 />,
    disabled: false,
  },
];

class NuovoPalinsesto extends React.Component {
  state = { current: 0 };

  getDate = (datetime) => {
    const dateObj = new Date(datetime);
    const hours =
      dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours();
    const minutes =
      dateObj.getMinutes() < 10
        ? "0" + dateObj.getMinutes()
        : dateObj.getMinutes();
    return hours + ":" + minutes;
  };

  getDay = (datetime) => {
    return new Date(datetime).getDay();
  };

  getDuration = (startDateTime, endDateTime) => {
    const startDateObj = new Date(startDateTime);
    const endDateObj = new Date(endDateTime);
    return (
      endDateObj.getHours() * 60 +
      endDateObj.getMinutes() -
      (startDateObj.getHours() * 60 + startDateObj.getMinutes())
    );
  };

  parseData = () => {
    const eventiCopy = JSON.parse(JSON.stringify(palinsestoData.eventi));
    palinsestoData.eventi = [];

    if (eventiCopy.length > 0) {
      eventiCopy.forEach((item) => {
        const itemStart = item.start || item.extendedProps.startTime;
        const itemEnd = item.end || item.extendedProps.endTime;
        const itemDayOfWeek = item.start
          ? this.getDay(item.start)
          : item.extendedProps.dayOfWeek;
        const duration = item.start
          ? this.getDuration(item.start, item.end)
          : item.extendedProps.duration;
        const evento = {
          id: item.id,
          title: item.title,
          startTime: this.getDate(itemStart),
          endTime: this.getDate(itemEnd),
          allDay: false,
          daysOfWeek: [itemDayOfWeek],
          extendedProps: {
            palinsesto: item.extendedProps.palinsesto,
            idItem: item.extendedProps.idItem,
            idContent: item.extendedProps.id_content,
            isNew: item.extendedProps.isNew,
            startTime: this.getDate(itemStart),
            endTime: this.getDate(itemEnd),
            duration: duration,
            dayOfWeek: itemDayOfWeek,
            elementsToHide: item.extendedProps.elementsToHide,
            orderedList: item.extendedProps.orderedList,
          },
          durationEditable: true,
          startEditable: true,
        };
        palinsestoData.eventi.push(evento);
      });
    }
  };

  verifyEmpty = () => {
    if (this.state.current === 0) {
      if (
        palinsestoData.categoria === 0 ||
        !palinsestoData.palinsesto ||
        palinsestoData.palinsesto.length === 0 ||
        palinsestoData.items.length === 0
      ) {
        Swal.fire({
          icon: "error",
          text: "Inserire tutte le informazioni",
        });
        return true;
      }
    }
    return false;
  };

  onChange = (current) => {
    if (this.verifyEmpty()) return;
    this.parseData();
    this.setState({ current });
  };

  next = () => {
    if (this.verifyEmpty()) return;
    this.parseData();
    this.setState({ current: this.state.current + 1 });
  };

  prev = () => {
    this.setState({ current: this.state.current - 1 });
  };

  salvaPalinsesto = () => {
    axios
      .post(
        process.env.REACT_APP_BASE_URL + "inserisci-palinsesto",
        palinsestoData
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(() => {
            fetch(
              process.env.REACT_APP_BASE_URL +
                "/autoplan?uuid=" +
                palinsestoData.uuid
            ).then(() => {
              window.location.href = "palinsesti?tab=palinsesti-sub";
            });
          });
        } else {
          throw new Error(response.status);
        }
      })
      .catch(() => {
        Swal.fire({
          title: "",
          html:
            "Errore nel salvataggio. <b>" + palinsestoData.palinsesto + "</b>",
          icon: "error",
        });
      });
  };

  render() {
    const { current } = this.state;

    return (
      <>
        <Steps
          style={{ margin: "0 auto", width: "90%" }}
          type="navigation"
          className="nav-steps"
          current={current}
          onChange={this.onChange}
        >
          <Step
            title="informazioni generali"
            description="Inserisci le informazioni generali per definire il palinsesto."
          />
          <Step
            title="Gestione brand"
            description="Gestisci la distribuzione dei brand durante i giorni della settimana."
          />
          <Step
            title="Impostazione template"
            description="Seleziona il template da associare al palinsesto che stai creando."
          />
        </Steps>
        <div className="nuovo-palinsesto">
          {steps[current].content}
          <div className="steps-actions">
            {current > 0 && (
              <Button className="step-previous" onClick={this.prev}>
                Indietro
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" className="step-next" onClick={this.next}>
                Avanti
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                className="step-next"
                onClick={this.salvaPalinsesto}
              >
                Salva palinsesto
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export { NuovoPalinsesto as default, palinsestoData as array };
