import { InputLabel, NativeSelect } from "@material-ui/core";
import { Typography } from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

const PlayerAssocia = (props) => {
  const [currentUser] = useState(props.currentUser);
  const [licenze, setLicenze] = useState([]);
  const [palinsesti, setPalinsesti] = useState([]);
  const [palinsesto, setPalinsesto] = useState();
  const [licenza, setLicenza] = useState();
  const [codeFirstPart, setCodeFirstPart] = useState(
    props.playerData ? props.playerData.codice_player.split("-")[0] : ""
  );
  const [codeLastPart, setCodeLastPart] = useState(
    props.playerData ? props.playerData.codice_player.split("-")[1] : ""
  );

  const [shop, setShop] = useState();
  const [playerName, setPlayerName] = useState(
    props.playerData ? props.playerData.display : ""
  );
  const codeLastPartInput = useRef(null);
  function onModalClose() {
    props.onCloseModal();
  }
  const [pos, setPos] = useState([]);
  const paste = (e) => {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    var split = text.split("-");
    if (split.length === 2) {
      setCodeFirstPart(split[0]);
      setCodeLastPart(split[1]);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setShopLicences(shop);
  }, [shop]);

  /* eslint-disable */
  useEffect(() => {
    if (props.playerData) {
      if (props.playerData.id_punto_vendita) {
        handleChange({
          currentTarget: { value: props.playerData.id_punto_vendita },
        });
      }
      if (props.playerData.codice_licenza) {
        handleChangeLicense({
          currentTarget: { value: props.playerData.codice_licenza },
        });
      }
      if (props.playerData.id_pal) {
        setPalinsesto(
          palinsesti.find((p) => p.uuid == props.playerData.id_pal)
        );
      }
    }
  }, [pos, palinsesti]);

  const fetchData = () => {
    fetch(process.env.REACT_APP_BASE_URL + "punti-vendita/1")
      .then((res) => res.json())
      .then((res) => {
        if (currentUser.tipo > 2) {
          if (res.length === 0) {
            setPos([]);
          } else {
            const licensedShop = res.filter(
              (p) => p.id_referente === currentUser.userid
            );
            if (licensedShop.length === 0) {
              setPos([]);
              setShop();
            } else {
              setPos(licensedShop);
              setShop(licensedShop[0]);
              setShopLicences(licensedShop[0]);
            }
          }
        } else {
          if (res.length === 0) {
            setPos([]);
            setShop();
          } else {
            setPos(res);
            setShop(res[0]);
            setShopLicences(res);
          }
        }
      });

    fetch(
      `${process.env.REACT_APP_BASE_URL}palinsesti${
        currentUser.tipo === 3 ? `/${currentUser.userid}` : ""
      }`
    )
      .then((res) => res.json())
      .then((res) => {
        setPalinsesti(res);
      });
  };

  const setShopLicences = (currentPos) => {
    try {
      const availableLicenses =
        currentPos !== undefined
          ? currentPos.licenze.filter((licenza) => licenza.status === 0)
          : [];

      var currentLicense;
      if (props.playerData && props.playerData.codice_licenza) {
        currentLicense = currentPos.licenze.find(
          (licenza) => licenza.codice === props.playerData.codice_licenza
        );
        availableLicenses.push(currentLicense);
      }

      if (availableLicenses.length > 0) {
        if (currentLicense) {
          setLicenza(currentLicense);
        } else {
          setLicenza(availableLicenses[0]);
        }
      } else {
        setLicenza();
      }
      setLicenze(availableLicenses);
    } catch (error) {
      console.log("ERR", error);
      setLicenze([]);
    }
  };

  const handleChange = (e) => {
    let currentPos = pos.find((p) => {
      return p.id === parseInt(e.currentTarget.value);
    });
    if (!currentPos) {
      setLicenze([]);
      setLicenza();
      setPalinsesto();
    } else {
      setShop(currentPos);
    }
  };

  const handleChangeLicense = (e) => {
    let lic = licenze.find((l) => {
      return l.codice === e.currentTarget.value;
    });
    setLicenza(lic);
    setPalinsesto();
  };

  console.log(currentUser);

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}associa-player-licenza`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        licenza: licenza.codice,
        pos: shop.id,
        display: playerName,
        codice: `${codeFirstPart}-${codeLastPart}`,
        durata: licenza.mesi,
      }),
    }).then((resAssoc) => {
      if (resAssoc.status == 200) {
        fetch(`${process.env.REACT_APP_BASE_URL}licenza-palinsesto`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uuid: palinsesto.uuid,
            codice: licenza.codice,
          }),
        }).then(() => {
          Swal.fire({
            title: "",
            text: `Player TV configurato con successo!`,
            icon: "success",
          });
          onModalClose();
        });
      } else {
        Swal.fire({
          title: "",
          text: "Errore nella configurazione della TV. Verificare i dati inseriri e riprovare!",
          icon: "error",
        });
      }
    });
  };

  const dataIsNotCorrect = useMemo(() => {
    return (
      !licenza ||
      !palinsesto ||
      !codeFirstPart ||
      codeFirstPart.length !== 4 ||
      !codeLastPart ||
      codeLastPart.length !== 4
    );
  }, [licenza, palinsesto, codeFirstPart, codeLastPart]);

  return (
    <div className="associa-player">
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" gutterBottom component="div">
          {props.playerData
            ? "Modifica del player"
            : "Associazione di una nuova TV"}
        </Typography>
        {pos.length > 0 || currentUser.tipo < 3 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "3%",
                marginTop: "3%",
              }}
            >
              <div>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Punto vendita
                </InputLabel>
                <NativeSelect
                  value={shop ? shop.id : null}
                  onChange={handleChange}
                >
                  {pos.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.nome}
                    </option>
                  ))}
                </NativeSelect>
              </div>
              <div>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Licenza associata
                </InputLabel>
                {licenze.length > 0 ? (
                  licenza && (
                    <NativeSelect
                      value={licenza.codice}
                      onChange={handleChangeLicense}
                    >
                      {licenze.map((licenza) => (
                        <option key={licenza.codice} value={licenza.codice}>
                          {licenza.codice}
                        </option>
                      ))}
                    </NativeSelect>
                  )
                ) : (
                  <Typography variant="caption" display="block" gutterBottom>
                    Nessuna licenza disponibile
                  </Typography>
                )}
              </div>
              <div>
                {licenza ? (
                  <>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Associa palinsesto
                    </InputLabel>
                    <NativeSelect
                      value={palinsesto ? palinsesto.uuid : ""}
                      onChange={(e) => {
                        console.log(palinsesti);
                        setPalinsesto(
                          palinsesti.find((p) => p.uuid == e.target.value)
                        );
                      }}
                    >
                      <option key="null" value="" disabled />
                      {palinsesti.map((palinsesto) => (
                        <option key={palinsesto.uuid} value={palinsesto.uuid}>
                          {palinsesto.nome}
                        </option>
                      ))}
                    </NativeSelect>
                  </>
                ) : null}
              </div>
            </div>

            <div className="form-row" style={{ padding: 0 }}>
              <InputLabel variant="standard" htmlFor="playercode">
                {props.playerData
                  ? "Codice del player"
                  : "Inserire il codice che appare nella schermata del player"}
              </InputLabel>
              <input
                onPaste={paste}
                className="form-input-associa"
                value={codeFirstPart}
                type="text"
                name="playercodeleft"
                onInput={(e) => {
                  e.target.value = ("" + e.target.value).toUpperCase();
                  setCodeFirstPart(e.target.value);
                  if (e.target.value.length !== 4) return;
                  codeLastPartInput.current.focus();
                }}
                style={{
                  maxWidth: "100px",
                  textAlign: "center",
                  opacity: props.playerData ? 0.6 : 1,
                }}
                disabled={props.playerData}
                maxLength={4}
                required
                placeholder="XXXX"
              ></input>{" "}
              -{" "}
              <input
                className="form-input-associa"
                type="text"
                name="playercoderight"
                ref={codeLastPartInput}
                value={codeLastPart}
                onInput={(e) => {
                  e.target.value = ("" + e.target.value).toUpperCase();
                  setCodeLastPart(e.target.value);
                }}
                disabled={props.playerData}
                style={{
                  maxWidth: "100px",
                  textAlign: "center",
                  opacity: props.playerData ? 0.6 : 1,
                }}
                maxLength={4}
                required
                placeholder="XXXX"
              ></input>
            </div>
            <div className="form-row" style={{ padding: 0 }}>
              <InputLabel variant="standard" htmlFor="playerdisplay">
                Ubicazione Display (opzionale)
              </InputLabel>
              <input
                className="form-input-associa"
                type="text"
                name="playerdisplay"
                placeholder="Esempio: Vetrina ingresso"
                value={playerName}
                onInput={(e) => setPlayerName(e.target.value)}
              ></input>
            </div>
            <div style={{ width: "100%", textAlign: "right" }}>
              <Button
                className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
                type="submit"
                disabled={dataIsNotCorrect}
              >
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  style={{ marginBottom: 0 }}
                >
                  {props.playerData ? "Modifica" : "Associa"}
                </Typography>
              </Button>
              <Button
                style={{
                  backgroundColor: "rgba(0,0,0,0.4)",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
                className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
                type="button"
                onClick={onModalClose}
              >
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  style={{ marginBottom: 0 }}
                >
                  Annulla
                </Typography>
              </Button>
            </div>
          </>
        ) : (
          <>
            <Typography variant="caption" display="block" gutterBottom>
              La tua utenza non risulta essere associata come referente ad alcun
              punto vendita
            </Typography>
          </>
        )}
      </form>
    </div>
  );
};
export default PlayerAssocia;
