import { useEffect, useState, useRef } from "react";
import plus from "../../images/plus.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { iniziativaData } from "./nuova-iniziativa";
import { v4 as uuidv4 } from "uuid";
function NuovaIniziativa1() {
  const [itemsBrands, setItemsBrands] = useState([]);
  const [itemsCatBrands, setCatItemsBrands] = useState([]);
  const [itemsInfinite, setItemsInfinite] = useState([]);
  const [itemsCategorie, setItemsCategorie] = useState([]);

  //const refCat = useRef();
  const refOthers = useRef();
  const refBrand = useRef();
  const refCat = useRef();
  const refNome = useRef();
  const refUUID = useRef();
  const refDiv = useRef();
  //let partialShow = 0;

  const fetchCatBrands = (idCat) => {
    let url = process.env.REACT_APP_BASE_URL + "brands";
    if (idCat && idCat > 0) {
      url += "/" + idCat;
    } else {
      url += "/" + refCat.current.value;
    }

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setCatItemsBrands([]);
        setCatItemsBrands(res);
      });
    refDiv.current.hidden = true;
  };
  const fetchAllBrands = () => {
    let url = process.env.REACT_APP_BASE_URL + "brands";
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        let catFilter = itemsCatBrands.map((item) => {
          return item.id;
        });
        let catFiltered = res.filter((item) => !catFilter.includes(item.id));
        setItemsBrands(catFiltered);
        // partialShow = 10;
        setItemsInfinite(catFiltered);
      });
  };

  /**const fetchMoreData = () => {
    partialShow = itemsInfinite.length + 10;
    let catFilter = itemsCatBrands.map(item => { return item.id; });
    let catFiltered = itemsBrands.filter(item => !catFilter.includes(item.id));
    setItemsBrands(catFiltered);
    setItemsInfinite(catFiltered);
    refOthers.current.hidden = false;
   
  };**/
  const fetchAll = () => {
    fetchAllBrands();
    refOthers.current.hidden = true;
    refDiv.current.hidden = false;
  };
  const handleChangeCategorie = (e) => {
    // partialShow = itemsInfinite.length;
    setItemsInfinite([]);
    fetchCatBrands(e.target.value);
    refOthers.current.hidden = false;
    refDiv.current.hidden = true;
    iniziativaData.categoria = e.target.value;
  };

  const fetchCategorie = () => {
    fetch(process.env.REACT_APP_BASE_URL + "categorie")
      .then((res) => res.json())
      .then((res) => {
        setItemsCategorie(res);
        if (iniziativaData && iniziativaData.uuid === "") {
          iniziativaData.uuid = uuidv4();
        }
        refCat.current.value = iniziativaData.categoria;
      });
  };
  const toggleItem = (i, e) => {
    if (e) {
      if (e.checked) {
        iniziativaData.items.push(i);
      } else {
        var index = iniziativaData.items.indexOf(
          iniziativaData.items.find((ii) => {
            return ii.id === i.id;
          })
        );
        if (index !== -1) {
          iniziativaData.items.splice(index, 1);
        }
      }
    }
  };
  function getChecked(idItem) {
    try {
      return iniziativaData.items.find((item) => {
        return item.id === idItem;
      });
    } catch (error) {
      return false;
    }
  }
  useEffect(() => {
    refNome.current.value = iniziativaData.iniziativa;
    fetchCategorie();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      fetchCatBrands();
    }, 500);
  }, []);
  /**useEffect(() => {
    setTimeout(() => {
      if ( iniziativaData.items && iniziativaData.items.length > itemsCatBrands.length && iniziativaData.categoria > 0)
      {
        fetchAll();
      }
    }, 1000);
  }, []); 
  **/

  return (
    <>
      <div className="">
        <input
          ref={refUUID}
          className="form-input"
          type="hidden"
          name="uuid"
          placeholder="Nome"
        />
        <input
          ref={refNome}
          className="form-input"
          type="text"
          name="nome"
          onChange={(e) => (iniziativaData.iniziativa = e.target.value)}
          placeholder="Nome"
        />
        <select
          ref={refCat}
          className="form-select-option"
          onChange={handleChangeCategorie}
          defaultValue={iniziativaData.categoria}
        >
          <option value={0}>Scegliere una categoria</option>
          {itemsCategorie.map((itemsCategorie) => (
            <option value={itemsCategorie.id}>{itemsCategorie.nome}</option>
          ))}
        </select>
        <p className="add-brand-list">
          Gestisci i brand della categoria selezionata.
        </p>
        <ul>
          {itemsCatBrands.map((itemsCatBrand) => (
            <li>
              <input
                ref={refBrand}
                type="checkbox"
                id={"brand" + itemsCatBrand.id}
                defaultChecked={getChecked(itemsCatBrand.id)}
                onChange={(e) => {
                  toggleItem(itemsCatBrand, e.currentTarget);
                }}
              />
              <label for={"brand" + itemsCatBrand.id}>
                <img
                  className=""
                  src={
                    process.env.REACT_APP_BASE_URL_CONTENTS + itemsCatBrand.logo
                  }
                  alt=""
                  style={{
                    display: "block",
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                />
              </label>
            </li>
          ))}
          <li ref={refOthers}>
            <label>
              <img
                className=""
                src={plus}
                alt="altri"
                title="altri"
                onClick={fetchAll}
              />{" "}
            </label>{" "}
          </li>
        </ul>
        <InfiniteScroll
          dataLength={itemsInfinite.length}
          //next={ fetchMoreData }
          hasMore={itemsInfinite.length !== itemsBrands.length}
        >
          <div ref={refDiv}>
            <hr />
            <p className="add-brand-list">
              Seleziona altri brand da aggiungere alla lista
            </p>
          </div>
          <ul>
            {itemsInfinite.map((itemsInfinite) => (
              <li>
                <input
                  ref={refBrand}
                  type="checkbox"
                  id={"brand" + itemsInfinite.id}
                  defaultChecked={getChecked(itemsInfinite.id)}
                  onChange={(e) => {
                    toggleItem(itemsInfinite, e.currentTarget);
                  }}
                />
                <label for={"brand" + itemsInfinite.id}>
                  <img
                    className=""
                    src={
                      process.env.REACT_APP_BASE_URL_CONTENTS +
                      itemsInfinite.logo
                    }
                    alt=""
                  />
                </label>
              </li>
            ))}
          </ul>
        </InfiniteScroll>
      </div>
    </>
  );
}

export { NuovaIniziativa1 as default };
