import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { itemBrand } from "./brands";

function ModificaBrand() {
  const [file, setFile] = useState("");
  const history = useHistory();

  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 100);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const timestamp = Date.now();
    const newFileName = `${timestamp}_${selectedFile.name}`;
    const renamedFile = new File([selectedFile], newFileName, {
      type: selectedFile.type,
    });
    setFile(renamedFile);
    itemBrand.logoBrand = newFileName;

    const imgLogo = document.getElementById("logoImg");
    imgLogo.src = URL.createObjectURL(renamedFile);
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(process.env.REACT_APP_BASE_URL + "upload-file", formData)
      .then((res) => {
        if (res.status === 200) {
          history.push("/impostazioni");
        } else {
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const selectedFile = event.target.brandlogo.files[0];
    const data = {};

    for (let i = 0; i < form.elements.length; i++) {
      const elem = form.elements[i];
      if (elem.name) data[elem.name] = elem.value;
    }

    if (selectedFile) {
      data["logo"] = file.name;
    } else {
      data["logo"] = itemBrand.logo;
    }

    axios
      .post(process.env.REACT_APP_BASE_URL + "modifica-brand", data)
      .then((response) => {
        if (response.status === 200) {
          if (selectedFile) {
            uploadFile();
          } else {
            history.push("impostazioni");
          }
        } else {
          throw new Error(response.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="">
        <div className="card">
          <div className="card-header">
            <p className="subtab">Modifica brand</p>
          </div>
          <div className="card-body">
            <form
              id="formBrand"
              controlId="modificaBrand"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <input
                className="form-input"
                type="hidden"
                name="brandid"
                value={itemBrand.id}
                onChange={handleChange}
              />
              <div className="form-row">
                <label className="form-label">NOME</label>
                <input
                  className="form-input"
                  type="text"
                  name="brandnome"
                  defaultValue={itemBrand.nome}
                />
              </div>
              <div className="form-row">
                <label className="form-label">LOGO</label>
                <input
                  className="form-input"
                  type="file"
                  name="brandlogo"
                  onChange={handleChange}
                />
              </div>
              <div className="form-row">
                <div className="form-image">
                  <img
                    id="logoImg"
                    className="brand-image"
                    src={
                      process.env.REACT_APP_BASE_URL_CONTENTS + itemBrand.logo
                    }
                    alt=""
                  />
                </div>
                <div className="form-buttons">
                  <Button
                    className="btn btn-outline-primary rounded-1 btn-sm"
                    type="submit"
                  >
                    Invia Modifica
                  </Button>
                  <Button
                    className="btn btn-outline-primary rounded-1 btn-sm"
                    onClick={() => {
                      history.push("impostazioni");
                    }}
                    type="button"
                  >
                    Annulla
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModificaBrand;
