import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import RichiestaLicenze from "./richiesta-licenze";
import PlayerAssocia from "./player-associa";
import Swal from "sweetalert2";
import { Typography, Button } from "@mui/material";
import { Add, Delete, Edit, LinkOff, LiveTv } from "@mui/icons-material";
import { default as PlayerPreview } from "./player-preview";
import moment from "moment";
import LinearWithValueLabel from "../components/LinearProgressWithLabel";
import CircleButtonComponent from "../components/Button/CircleButton";

function PlayerSub(props) {
  const [currentUser] = useState(props.currentUser);
  const [players, setPlayers] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [allLicences, setAllLicences] = useState([]);
  const [selectedScheduleUuid, setSelectedScheduleUuid] = useState("");
  const [displayScheduleModal, setDisplayScheduleModal] = useState(false);
  const [showReq, setShowReq] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedPlayerData, setSelectedPlayerData] = useState();
  const handleCloseReq = () => {
    setShowReq(false);
    fetchPlayers();
  };
  const handleShowReq = () => setShowReq(true);
  const handleClose = () => {
    setShow(false);
    setSelectedPlayerData(undefined);
    fetchPlayers();
    fetchLicences();
  };
  const handleShow = () => setShow(true);

  const handleDisassociate = (licenseCode, playerCode) => {
    fetch(process.env.REACT_APP_BASE_URL + "dissociateplayerlicense", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        codice: [licenseCode],
        codice_player: [playerCode],
      }),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "TV disassociata correttamente",
            icon: "success",
          })
            .then(function () {})
            .then(function () {
              fetchPlayers();
              fetchLicences();
            });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Impossibile dissociare la licenza selezionata",
          icon: "error",
        });
      });
  };

  const clickDeleteButton = (item) => {
    Swal.fire({
      title: "Confermi?",
      html: `Attenzione, il TV <b> "${item.display ? item.display : item.nome}
      "</b> sarà eliminato!</div>`,
      showCancelButton: true,
      confirmButtonText: "Elimina",
      cancelButtonText: "Annulla",
      reverseButtons: true,
      icon: "question",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePlayer(item);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({ title: "", text: "Operazione annullata", icon: "error" });
      }
    });
  };

  const deletePlayer = (item) => {
    const dataDel = {};
    dataDel["id"] = item.uuid;
    fetch(`${process.env.REACT_APP_BASE_URL}player/${item.id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(() => {
            fetchPlayers();
            fetchLicences();
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare il TV selezionato",
          icon: "error",
        });
      });
  };

  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);

  const fetchPlayers = () => {
    let url = "players";
    if (currentUser.tipo > 2) {
      url += "-cliente/" + currentUser.userid;
    } else {
      url += "-associated";
    }

    fetch(process.env.REACT_APP_BASE_URL + url)
      .then((res) => res.json())
      .then((res) => {
        setPlayers(res);
      });
  };
  const fetchLicences = () => {
    fetch(process.env.REACT_APP_BASE_URL + "licenze")
      .then((res) => res.json())
      .then((res) => {
        setAllLicences(res);
      });
  };

  const checkIfLicenceIsExpired = (date) => {
    return moment(date).diff(moment()) < 0;
  };

  const getLicencesComponent = useMemo(() => {
    if (!allLicences || allLicences.length === 0) return "";
    const activeLicences = allLicences.filter(
      (licence) => parseInt(licence.status) === 1
    ).length;
    const counterTotal = allLicences.length;

    return (
      <>
        {currentUser.tipo !== 3 && (
          <>
            <Typography variant="subtitle2" gutterBottom component="div">
              Licenze attive
            </Typography>
            <LinearWithValueLabel
              value={activeLicences}
              text={activeLicences + "/" + counterTotal}
            />
          </>
        )}
      </>
    );
  }, [allLicences]);

  /* eslint-disable */
  useEffect(() => {
    fetchPlayers();
    fetchLicences();
  }, []);

  const displaySchedulePreview = (player) => {
    setSelectedScheduleUuid(player.id_pal);
    setDisplayScheduleModal(true);
  };

  const handlePreviewClose = () => {
    setSelectedScheduleUuid("");
    setDisplayScheduleModal(false);
  };

  /* eslint-disable */
  useEffect(() => {
    let itemsArray = JSON.parse(JSON.stringify(players));
    // license.scadenza = license.status > 0 && license.status !== 2;
    let userData = [];

    itemsArray.map((item) => {
      item.nomedisplay = item.display ? item.display : "N/A";
      item.nomepalinsesto = item.palinsesto ? item.palinsesto : "N/A";
      item.action = (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CircleButtonComponent
            icon={<LiveTv />}
            onClick={() => {
              displaySchedulePreview(item);
            }}
          />
          {currentUser.tipo > 0 && (
            <CircleButtonComponent
              icon={<LinkOff />}
              onClick={() => {
                handleDisassociate(item.codice_licenza, item.codice_player);
              }}
            />
          )}
          {currentUser.tipo <= 2 && (
            <>
              <CircleButtonComponent
                icon={<Edit />}
                onClick={() => {
                  handleShow();
                  setSelectedPlayerData(item);
                }}
              />
              <CircleButtonComponent
                icon={<Delete />}
                onClick={() => clickDeleteButton(item)}
              />
            </>
          )}
        </div>
      );
      userData.push(item);
    });
    setPlayersData(userData);
  }, [players]);
  let updatedPlayersData = playersData.map((player) => {
    let updatedPlayer = { ...player };
    let licence = allLicences.find(
      (licence) => licence.codice === player.codice_licenza
    );
    if (licence) {
      updatedPlayer.scadenza = moment(licence.data_scadenza).format(
        "DD/MM/YYYY"
      );
    }
    return updatedPlayer;
  });

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: 0,
      },
      {
        label: "Display",
        field: "nomedisplay",
        sort: "asc",
        width: 370,
      },
      {
        label: "Punto vendita",
        field: "punto_vendita",
        sort: "asc",
        width: 200,
      },
      {
        label: "Palinsesto",
        field: "nomepalinsesto",
        sort: "asc",
        width: 200,
      },
      {
        label: "Codice Player",
        field: "codice_player",
        sort: "asc",
        width: 120,
      },
      {
        label: "Codice Licenza",
        field: "codice_licenza",
        sort: "asc",
        width: 120,
      },
      {
        label: "Scadenza Licenza",
        field: "scadenza",
        sort: "asc",
        width: 120,
      },
      {
        label: "Azione",
        field: "action",
        width: 50,
        sort: "disabled",
      },
    ],
    rows: updatedPlayersData,
  };

  return (
    <>
      <div className="tabella-player">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" gutterBottom component="div">
              Gestisci i TV in esecuzione
            </Typography>
            {getLicencesComponent}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "2%",
              gap: "20px",
              maxWidth: "28%",
            }}
          >
            <CircleButtonComponent
              text="Collega la TV"
              icon={<LiveTv />}
              onClick={() => {
                window.open("https://d2ocklk6c40eyp.cloudfront.net", "_blank");
              }}
            />
            <CircleButtonComponent
              icon={<Add />}
              onClick={() => {
                handleShow();
              }}
            />
          </div>
        </div>

        {players.length > 0 ? (
          <MDBDataTableV5
            hover
            data={data}
            proSelect
            searchTop
            searchBottom={false}
            fullPagination
            searchAlign="left"
            searchLabel={"Cerca Player"}
            responsive
          />
        ) : (
          <Typography variant="caption" display="block" gutterBottom>
            Nessun TV trovato
          </Typography>
        )}

        {/* FF */}

        <div style={{ marginTop: "40px" }} hidden={currentUser.tipo <= 2}>
          <button
            onClick={() => {
              handleShowReq();
            }}
            className="aggiungi"
          >
            Richiesta Licenze
          </button>
        </div>
      </div>

      <Modal
        center
        show={displayScheduleModal}
        onRequestClose={() => handlePreviewClose}
        className="schedule-modal-player"
      >
        <Modal.Body style={{ width: "fit-content" }}>
          <Button
            style={{ float: "right", zIndex: 900 }}
            variant="link"
            onClick={() => setDisplayScheduleModal(false)}
          >
            <i style={{ fontSize: "120%" }} className="bi bi-x"></i>
          </Button>
          <PlayerPreview scheduleId={selectedScheduleUuid} />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modal-messages"
        show={showReq}
        onRequestClose={handleCloseReq}
      >
        <Modal.Body>
          <Button
            style={{ float: "right" }}
            variant="link"
            onClick={handleCloseReq}
          >
            <i style={{ fontSize: "120%" }} className="bi bi-x"></i>
          </Button>
          <RichiestaLicenze
            onCloseModal={handleCloseReq}
            title="Richiesta Licenze"
          />
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName="modal-messages"
        show={show}
        onRequestClose={handleClose}
      >
        <Modal.Body>
          <Button
            style={{ float: "right" }}
            variant="link"
            onClick={handleClose}
          >
            <i style={{ fontSize: "120%" }} className="bi bi-x"></i>
          </Button>
          <PlayerAssocia
            playerData={selectedPlayerData}
            onCloseModal={handleClose}
            currentUser={currentUser}
            title="Associa Licenza a Player"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PlayerSub;
