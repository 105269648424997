import { useEffect, useState, useRef } from "react";

export default function UtentiAssociaBrands(props) {
  const chkRef = useRef();
  const [itemsBrands, setItemsBrands] = useState([]);

  const fetchBrands = () => {
    fetch(process.env.REACT_APP_BASE_URL + "brands")
      .then((res) => res.json())
      .then((res) => {
        setItemsBrands(res);
      });
  };

  const getChecked = (itemId) => {
    return props.utenteBrands?.some((item) => item.id === itemId) || false;
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  if (itemsBrands.length === 0) {
    return <div className="App">Caricamento in corso...</div>;
  }

  return (
    <>
      <div className="nuovo-palinsesto">
        <div
          className="associa-brand"
          style={{ maxHeight: props.fullHeight ? "unset" : "76vh" }}
        >
          <ul>
            {itemsBrands.map((item, index) => (
              <li key={index}>
                <input
                  type="checkbox"
                  id={"brand" + item.id}
                  ref={chkRef}
                  defaultChecked={getChecked(item.id)}
                  onChange={() => {
                    props.toggleItem(item);
                  }}
                />
                <label
                  htmlFor={"brand" + item.id}
                  style={{ height: "350px", alignContent: "center" }}
                >
                  <img
                    className=""
                    src={process.env.REACT_APP_BASE_URL_CONTENTS + item.logo}
                    alt=""
                    style={{
                      width: "350px",
                      height: "auto",
                      margin: "0 auto",
                      objectFit: "contain",
                    }}
                  />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
