import { useEffect, useState, useRef } from "react";
import plus from "../../images/plus.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { palinsestoData } from "./nuovo-palinsesto";
import { v4 as uuidv4 } from "uuid";
import { Badge } from "material-ui";
import { PermMedia } from "@mui/icons-material";
function NuovoPalinsesto1() {
  const [itemsBrands, setItemsBrands] = useState([]);
  const [itemsCatBrands, setCatItemsBrands] = useState([]);
  const [itemsInfinite, setItemsInfinite] = useState([]);
  const [itemsCategorie, setItemsCategorie] = useState([]);

  const refOthers = useRef();
  const refBrand = useRef();
  const refCat = useRef();
  const refNome = useRef();
  const refUUID = useRef();
  const refDiv = useRef();

  const fetchCatBrands = (idCat) => {
    let url = process.env.REACT_APP_BASE_URL + "brands";
    if (idCat && idCat > 0) {
      url += "/" + idCat;
    } else {
      url += "/" + refCat.current.value;
    }

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setCatItemsBrands(res);
      });
    refDiv.current.hidden = true;
  };

  const fetchAllBrands = () => {
    let url = process.env.REACT_APP_BASE_URL + "brands";
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        let catFilter = itemsCatBrands.map((item) => {
          return item.id;
        });
        let catFiltered = res.filter((item) => !catFilter.includes(item.id));
        setItemsBrands(catFiltered);
        setItemsInfinite(catFiltered);
      });
  };
  const fetchAll = () => {
    fetchAllBrands();
    refOthers.current.hidden = true;
    refDiv.current.hidden = false;
  };
  const handleChangeCategorie = (e) => {
    setItemsInfinite([]);
    fetchCatBrands(e.target.value);
    refOthers.current.hidden = false;
    refDiv.current.hidden = true;
    palinsestoData.categoria = e.target.value;
  };

  const fetchCategorie = () => {
    fetch(process.env.REACT_APP_BASE_URL + "categorie")
      .then((res) => res.json())
      .then((res) => {
        setItemsCategorie(res);
        if (palinsestoData && palinsestoData.uuid === "") {
          palinsestoData.uuid = uuidv4();
        }
        refCat.current.value = palinsestoData.categoria;
      });
  };
  const toggleItem = (i, e) => {
    if (e) {
      if (e.checked && parseInt(i.contenuti) === 0) {
        e.checked = false;
        return;
      }
      if (e.checked) {
        palinsestoData.items.push(i);
      } else {
        var index = palinsestoData.items.indexOf(
          palinsestoData.items.find((ii) => {
            return ii.id === i.id;
          })
        );
        if (index !== -1) {
          palinsestoData.items.splice(index, 1);
        }
      }
    }
  };
  function getChecked(idItem) {
    try {
      return palinsestoData.items.find((item) => {
        return item.id === idItem;
      });
    } catch (error) {
      return false;
    }
  }
  useEffect(() => {
    refNome.current.value = palinsestoData.palinsesto;
    fetchCategorie();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      fetchCatBrands();
    }, 500);
  }, []);

  return (
    <>
      <div className="">
        <input
          ref={refUUID}
          className="form-input"
          type="hidden"
          name="uuid"
          placeholder="Nome"
        />
        <input
          ref={refNome}
          className="form-input"
          type="text"
          name="nome"
          onChange={(e) => (palinsestoData.palinsesto = e.target.value)}
          placeholder="Nome"
        />
        <select
          ref={refCat}
          className="form-select-option"
          onChange={handleChangeCategorie}
          defaultValue={palinsestoData.categoria}
        >
          <option value={0}>Scegliere una categoria</option>
          {itemsCategorie.map((itemsCategorie) => (
            <option value={itemsCategorie.id}>{itemsCategorie.nome}</option>
          ))}
        </select>
        <p className="add-brand-list">
          Gestisci i brand della categoria selezionata
        </p>
        <ul>
          {itemsCatBrands.map((itemsCatBrand) => (
            <li>
              <input
                ref={refBrand}
                type="checkbox"
                id={"brand" + itemsCatBrand.id}
                defaultChecked={getChecked(itemsCatBrand.id)}
                onChange={(e) => {
                  toggleItem(itemsCatBrand, e.currentTarget);
                }}
              />
              <label for={"brand" + itemsCatBrand.id}>
                <img
                  src={
                    process.env.REACT_APP_BASE_URL_CONTENTS + itemsCatBrand.logo
                  }
                  style={{
                    width: "auto",
                    Height: "fit-content",
                    margin: "0 auto",
                    objectFit: "contain",
                  }}
                  className={
                    parseInt(itemsCatBrand.contenuti) === 0
                      ? "brand-disabled"
                      : ""
                  }
                  alt=""
                />
              </label>
            </li>
          ))}
          <li ref={refOthers}>
            <label>
              <img
                className=""
                src={plus}
                alt="altri"
                title="altri"
                onClick={fetchAll}
                style={{
                  width: "auto",
                  Height: "fit-content",
                  margin: "0 auto",
                  objectFit: "contain",
                }}
              />{" "}
            </label>{" "}
          </li>
        </ul>
        <InfiniteScroll
          dataLength={itemsInfinite.length}
          hasMore={itemsInfinite.length !== itemsBrands.length}
        >
          <div ref={refDiv}>
            <hr />
            <p className="add-brand-list">
              Seleziona altri brand da aggiungere alla lista
            </p>
            <div
              style={{
                border: "1px solid #ffa500",
                borderRadius: 4,
                backgroundColor: "#fff6e6",
                color: "#ffa500",
                fontWeight: 500,
                fontSize: "1.1em",
                padding: 8,
                letterSpacing: "0.5px",
                marginBottom: 16,
              }}
            >
              Attenzione: I brand selezionati non appariranno nel palinsesto
              finché non gli verrà aggiunto un contenuto appartenente alla
              categoria selezionata.
            </div>
          </div>
          <ul>
            {itemsInfinite.map((itemsInfinite) => (
              <li>
                <input
                  ref={refBrand}
                  type="checkbox"
                  id={"brand" + itemsInfinite.id}
                  defaultChecked={getChecked(itemsInfinite.id)}
                  onChange={(e) => {
                    toggleItem(itemsInfinite, e.currentTarget);
                  }}
                />
                <label
                  for={"brand" + itemsInfinite.id}
                  style={{ height: "350px", alignContent: "center" }}
                >
                  <img
                    className={
                      parseInt(itemsInfinite.contenuti) === 0
                        ? "brand-disabled"
                        : ""
                    }
                    style={{
                      width: "350px",
                      height: "auto",
                      margin: "0 auto",
                      objectFit: "contain",
                    }}
                    src={
                      process.env.REACT_APP_BASE_URL_CONTENTS +
                      itemsInfinite.logo
                    }
                    alt=""
                  />
                </label>
              </li>
            ))}
          </ul>
        </InfiniteScroll>
      </div>
    </>
  );
}

export { NuovoPalinsesto1 as default };
