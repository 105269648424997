import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import NuovaCartella from "./nuova-cartella";
import Messaggio from "./messaggio";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import axios from "axios";
import catalogo from "../../images/catalogo.png";
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  TablePagination,
  TextField,
  Modal,
  Box,
  InputAdornment,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Checkbox,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Search,
  CheckCircle,
  AutoFixHigh,
  CreateNewFolder,
  Folder,
  Send,
  Delete,
  Visibility,
  AccountTree,
  Edit,
  OndemandVideo,
  Audiotrack,
  Image,
  Article,
  CloudDownload,
} from "@mui/icons-material";
import { setItemContenuti } from "./contenuti";
import { alpha } from "@mui/material/styles";
import { BASEPATH } from "../../CONST";
import { Grid } from "@material-ui/core";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Toast } from "react-bootstrap";
import CustomHeader from "../components/Header/CustomHeader";
import SectionSubtitle from "../components/Subtitles";

function Cartelle() {
  const history = useHistory();
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const [itemsCartelle, setItemsCartelle] = useState([]);
  const [itemsContenuti, setItemsContenuti] = useState([]);
  const [itemsContenutiRoot, setItemsContenutiRoot] = useState([]);

  const [modalShowed, setModalShowed] = useState({ type: "none" });
  const [messageData, setMessageData] = useState({});
  const [titleComponent, setTitleComponent] = useState("Nuovo Messaggio");
  const [modelsMessages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [brands, setBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedFolder, setSelectedFolder] = useState();
  const [editFolder, setEditFolder] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [downloadingQueue, setDownloadingQueue] = useState([]);

  const handleClose = () => {
    setModalShowed({ type: "none" });
  };

  const getContentsItems = async (idcartella) => {
    await fetch(
      process.env.REACT_APP_BASE_URL +
        `contenuti/${idcartella}/${selectedBrand.id}`
    )
      .then((res) => res.json())
      .then((res) => {
        setItemsContenuti(res);
      })
      .catch(() => {});
  };

  const scrollToContent = () => {
    setTimeout(() => {
      const element = document.querySelector(".MuiTableCell-root");
      if (element) {
        const elementPos = element.getBoundingClientRect().top;
        window.scrollTo({
          top: elementPos,
          behavior: "smooth",
        });
      }
    }, 1500);
  };

  const getModels = () => {
    try {
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 0,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 1,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 2,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 3,
        id_utente: currentUser.userid,
      });

      var data = { id_user: currentUser.userid, token: currentUser.idToken };
      axios
        .post(process.env.REACT_APP_BASE_URL + "get-models", data)
        .then((res) => {
          if (res.status === 200 && res.data.length > 0) {
            var datam = res.data;
            datam.forEach((i) => {
              modelsMessages.forEach((m) => {
                if (m.categoria === i.categoria) {
                  m.messaggio = i.messaggio;
                  m.id = i.id;
                }
              });
            });
          }
        })
        .catch(() => {});
    } catch (error) {}
  };

  const fetchBrands = () => {
    var requestPath = "";
    if (currentUser.tipo < 3) {
      requestPath = "brands";
    } else {
      requestPath = `get-clienti-brands/${currentUser.userid}`;
    }
    setIsLoading(true);
    fetch(process.env.REACT_APP_BASE_URL + requestPath)
      .then((res) => res.json())
      .then((res) => {
        setBrands(res);
        setFilteredBrands(res);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchItems = () => {
    fetch(process.env.REACT_APP_BASE_URL + `cartelle/${selectedBrand.id}`)
      .then((res) => res.json())
      .then((res) => {
        setItemsCartelle(res);
      })
      .catch(() => {});
  };

  const deleteContent = (item) => {
    const dataDel = {};
    dataDel["id"] = item.id;
    dataDel["filename"] = item.file;
    fetch(process.env.REACT_APP_BASE_URL + "elimina-contenuto/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(function () {
            refreshDatas();
          });
        }
      })
      .catch(function () {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare il contenuto selezionato",
          icon: "error",
        });
      });
  };

  const deleteContents = async () => {
    try {
      for (let i = 0; i < selectedItems.length; i++) {
        const dataDel = {};
        dataDel["id"] = selectedItems[i].id;
        dataDel["filename"] = selectedItems[i].file;
        await fetch(process.env.REACT_APP_BASE_URL + "elimina-contenuto/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataDel),
        });
      }
      Swal.fire({
        title: "",
        text: "Operazione eseguita correttamente",
        icon: "success",
      }).then(function () {
        refreshDatas();
      });
    } catch (e) {
      Swal.fire({
        title: "",
        text: "Impossibile eliminare il contenuto selezionato",
        icon: "error",
      });
    }
  };

  const getRootContentsItems = async () => {
    let requestUrlApi =
      process.env.REACT_APP_BASE_URL + `contenuti-root/${selectedBrand.id}`;
    await fetch(requestUrlApi)
      .then((res) => res.json())
      .then((res) => {
        setItemsContenutiRoot(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    refreshDatas();
  }, [selectedFolder]);

  useEffect(() => {
    getModels();
    fetchBrands();
    setTimeout(() => {
      var buttons = document.getElementsByClassName("page-link");
      if (buttons.length > 0) {
        for (var i = 0; i < buttons.length; i++) {
          buttons[i].onclick = function () {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          };
        }
      }
    }, 1000);
  }, []);

  useEffect(() => {
    refreshDatas();
  }, [selectedBrand]);

  const refreshDatas = () => {
    setSelectedItems([]);
    setItemsCartelle([]);
    setItemsContenutiRoot([]);
    setItemsContenuti([]);
    if (selectedFolder) {
      getContentsItems(selectedFolder.id);
    } else if (selectedBrand) {
      getRootContentsItems();
      fetchItems();
    }
  };

  const toggleAllItems = () => {
    if (selectedItems.length > 0) {
      setSelectedItems([]);
      return;
    }

    if (selectedFolder) {
      setSelectedItems(itemsContenuti);
    } else if (selectedBrand) {
      setSelectedItems(itemsContenutiRoot);
    }
  };

  const deleteFolder = (item) => {
    const dataDel = {};
    dataDel["id"] = item.id;
    dataDel["filename"] = item.file;
    fetch(process.env.REACT_APP_BASE_URL + "del-cartella/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(function () {
            refreshDatas();
          });
        }
      })
      .catch(function () {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare il contenuto selezionato",
          icon: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSelectedItems([]);
  }, [selectedFolder]);

  const calcSize = (s) => {
    let size = "0 Bytes";
    let um = " Bytes";
    if (s > 1024) {
      size = s / 1024;
      um = " KB";
    }
    if (size > 1024) {
      size = size / 1024;
      um = " MB";
    }
    if (size > 1024) {
      size = size / 1024;
      um = " TB";
    }
    return size.toLocaleString(undefined, { maximumFractionDigits: 2 }) + um;
  };

  const visualizzaContenuto = (item, viewOnlyContent) => {
    let videoTag = "none";
    let imageTag = "none";
    let docTag = "none";
    let downloadButton = "";
    let ext = item.file.split(".").pop().toLowerCase();
    if (
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png" ||
      ext === "bmp" ||
      ext === "gif"
    ) {
      imageTag = "";
      videoTag = "none";
      docTag = "none";
    } else if (
      ext === "mp4" ||
      ext === "m4v" ||
      ext === "mpg" ||
      ext === "mpeg" ||
      ext === "wmv" ||
      ext === "ts" ||
      ext === "mkv" ||
      ext === "avi" ||
      ext === "ps"
    ) {
      imageTag = "none";
      videoTag = "";
      docTag = "none";
    } else if (
      ext === "mp3" ||
      ext === "wav" ||
      ext === "wma" ||
      ext === "aac" ||
      ext === "ac3" ||
      ext === "mp2"
    ) {
      imageTag = "none";
      videoTag = "";
      docTag = "none";
    } else if (
      ext === "pdf" ||
      ext === "xlsx" ||
      ext === "xls" ||
      ext === "docx" ||
      ext === "doc" ||
      ext === "csv" ||
      ext === "ppt" ||
      ext === "pptx"
    ) {
      imageTag = "none";
      videoTag = "none";
      docTag = "";
      downloadButton =
        '<a href="' +
        process.env.REACT_APP_BASE_URL_CONTENTS +
        item.file +
        '" download="' +
        item.nome +
        "\" target='_blank'><i style='font-size:30px;' class='bi bi-cloud-arrow-down'></i></a>";
    }
    let brands = " - ";
    let categorie = " - ";
    let tags = " - ";
    let pubblicato = "Pubblicato";
    if (item.pubblicato === 0) {
      pubblicato = "Non pubblicato";
    }
    item.brands && item.brands.forEach((i) => (brands += i.nome + " - "));
    item.categorie &&
      item.categorie.forEach((i) => (categorie += i.nome + " - "));
    item.tags && item.tags.forEach((i) => (tags += i.nome + " - "));
    if (viewOnlyContent) {
      Swal.fire({
        html:
          "<video height='160px' style='display:" +
          videoTag +
          ";' controls src='" +
          process.env.REACT_APP_BASE_URL_CONTENTS +
          item.file +
          "' ></video><img height='160px' style='display:" +
          imageTag +
          ";' src='" +
          process.env.REACT_APP_BASE_URL_CONTENTS +
          item.file +
          "' alt='anteprima immagine' /><img height='160px' style='display:" +
          docTag +
          ";' src='" +
          catalogo +
          "' alt='catalogo' /><div style='width: 100%; text-align:right;'>",
        showCancelButton: false,
        confirmButtonText: "OK",
        reverseButtons: true,
      });
    } else {
      Swal.fire({
        html:
          "<video height='160px' style='display:" +
          videoTag +
          ";' controls src='" +
          process.env.REACT_APP_BASE_URL_CONTENTS +
          item.file +
          "' ></video><img height='160px' style='display:" +
          imageTag +
          ";' src='" +
          process.env.REACT_APP_BASE_URL_CONTENTS +
          item.file +
          "' alt='anteprima immagine' /><img height='160px' style='display:" +
          docTag +
          ";' src='" +
          catalogo +
          "' alt='catalogo' /><div style='width: 100%; text-align:right;'>" +
          downloadButton +
          "</div><table class='dettagli-contenuto'><tr><th>Nome</th><td>" +
          item.nome +
          " </td></tr><tr><th>Descrizione</th><td>" +
          item.descrizione +
          "</td></tr><tr><th>Tipo</th><td>" +
          item.tipologia +
          "</td></tr><tr><th>Dimensione</th><td>" +
          calcSize(item.dimensione) +
          "</td></tr><tr><th>Brands</th><td>" +
          brands +
          "</td></tr><tr><th>Categorie</th><td>" +
          categorie +
          "</td></tr><tr><th>Tag</th><td>" +
          tags +
          "</td></tr><tr><th>Scaricato</th><td>" +
          (item.downloads.some(
            (download) => download.email_user === currentUser.email
          )
            ? "Si"
            : "No") +
          "</td></tr><tr><th>Stato</th><td>" +
          pubblicato +
          "</td></tr><tr><th>Validit&agrave;</th><td>" +
          new Date(item.data_inizio_pubblicazione).toLocaleDateString("it", {
            weekday: "long",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }) +
          " <br /> " +
          new Date(item.data_fine_pubblicazione).toLocaleDateString("it", {
            weekday: "long",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }) +
          "</td></tr></table>",
        showCancelButton: false,
        confirmButtonText: "OK",
        reverseButtons: true,
      });
    }
  };

  function inviaMessaggio(item) {
    let genre = "o";
    if (item.tipologia === "Immagine") {
      genre = "a";
    }

    let subject = "Nuov" + genre + " " + item.tipologia + ": " + item.nome;

    let body = `<p>E' disponibile un nuovo contenuto per te all'indirizzo&nbsp;<a href="https://euracom.22hbg.com/">https://euracom.22hbg.com</a>. Accedi usando le tue credenziali di accesso:</p><ul><li>Contenuto: ((%contenuto%))</li></ul><p>Se hai domande, dubbi o bisogno di supporto siamo a tua disposizione all&rsquo;indirizzo:&nbsp;<a href="mailto:supporto@euracom.it">marketing@euracom.it</a>.</p>`;
    let locationItem = `https://${window.location.hostname}/${BASEPATH}/contenuti?tab=contenuti&action=contenuto&item=${item.id}`;

    body = body.replace(
      "((%contenuto%))",
      `<a href="${locationItem}">${item.nome}</a>`
    );
    body += modelsMessages[0].messaggio;
    setTitleComponent("Condividi " + item.nome);
    setMessageData({
      body: body,
      subject: subject,
      isresponse: false,
      issharing: true,
    });
    setModalShowed({ type: "newMessage" });
  }

  const isItemSelected = (item) =>
    selectedItems.find((i) => i.id === item.id) !== undefined;

  const toggleItem = (item, status) => {
    if (status) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
    }
  };

  const downloadFile = async (item) => {
    setDownloadingQueue([...downloadingQueue, item]);
    const blob = await fetch(
      `${process.env.REACT_APP_BASE_URL_CONTENTS}${item.file}`
    ).then((resp) => resp.blob());

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.target = "_blank";
    a.href = url;
    a.download = `${item.nome}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    fetch(
      `${process.env.REACT_APP_BASE_URL}contenuti/${item.id}/users/${currentUser.email}/download`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    ).then(function (response) {
      if (response.status === 200) {
        setDownloadingQueue(downloadingQueue.filter((i) => i.id !== item.id));
        refreshDatas();
      }
    });
  };

  const renderTableToolbar = () => {
    const numSelected = selectedItems.length;
    return (
      <Toolbar
        style={{
          marginTop: 20,
        }}
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} contenut{numSelected === 1 ? "o" : "i"} selezionat
            {numSelected === 1 ? "o" : "i"}
          </Typography>
        ) : (
          <CustomHeader title={selectedBrand && selectedBrand.nome} />
        )}

        {numSelected > 0 ? (
          <>
            <Tooltip title="Download">
              <CircleButtonComponent
                onClick={async () => {
                  for (let i = 0; i < selectedItems.length; i++) {
                    await downloadFile(selectedItems[i]);
                  }
                }}
                icon={<CloudDownload />}
              />
            </Tooltip>
            <Tooltip title="Elimina">
              <CircleButtonComponent
                color="secondary"
                onClick={() => {
                  if (currentUser.tipo < 3) {
                    Swal.fire({
                      title: "Confermi?",
                      html: "</span> Attenzione, i contenuti selezionati saranno eliminati!</div>",
                      showCancelButton: true,
                      confirmButtonText: "Elimina",
                      cancelButtonText: "Annulla",
                      reverseButtons: false,
                      icon: "question",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteContents();
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire("Operazione annullata");
                      }
                    });
                  }
                }}
                icon={<Delete />}
              />
            </Tooltip>
          </>
        ) : null}
      </Toolbar>
    );
  };

  const renderItemsTable = () => {
    const contents = [
      ...itemsCartelle,
      ...itemsContenutiRoot,
      ...itemsContenuti,
    ];

    const filteredContents = contents.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    const hasFiles =
      filteredContents.find((e) => e.tipologia !== undefined) !== undefined;

    return filteredContents.length > 0 || selectedFolder ? (
      <>
        {renderTableToolbar()}
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                {hasFiles ? (
                  <TableCell
                    padding="checkbox"
                    variant="body2"
                    style={{ paddingLeft: "2px" }}
                  >
                    <b>Seleziona Tutto</b>
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < contents.length
                      }
                      checked={
                        contents.length > 0 &&
                        selectedItems.length === contents.length
                      }
                      onChange={toggleAllItems}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                ) : undefined}

                <TableCell style={{ paddingLeft: "40px" }}>
                  <b>Anteprima</b>
                </TableCell>
                <TableCell style={{ paddingLeft: "40px" }}>
                  <b>Nome</b>
                </TableCell>
                <TableCell style={{ paddingLeft: "20px" }}>
                  <b>Stato</b>
                </TableCell>
                <TableCell>
                  <b>Azione</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFolder ? (
                <>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        textAlign: "center",
                      }}
                      width={"120"}
                    >
                      <Folder
                        onClick={() => {
                          setSelectedFolder(undefined);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          cursor: "pointer",
                          fontWeight: 900,
                          fontSize: 40,
                        }}
                        onClick={() => {
                          setSelectedFolder(undefined);
                        }}
                      >
                        ...
                      </span>
                    </TableCell>
                  </TableRow>
                </>
              ) : undefined}

              {filteredContents.map((row) => {
                var mediaIcon = <Folder />;
                switch (row.tipologia) {
                  case undefined:
                    mediaIcon = <Folder />;
                    break;
                  case "Video":
                    mediaIcon = (
                      <video
                        style={{ height: "200px", width: "200px" }}
                        src={process.env.REACT_APP_BASE_URL_CONTENTS + row.file}
                        controls
                      />
                    );
                    break;
                  case "Immagine":
                    mediaIcon = (
                      <img
                        style={{ height: "200px", width: "200px" }}
                        src={process.env.REACT_APP_BASE_URL_CONTENTS + row.file}
                      />
                    );
                    break;
                  case "Audio":
                    mediaIcon = (
                      <audio
                        style={{ height: "200px", width: "200px" }}
                        src={process.env.REACT_APP_BASE_URL_CONTENTS + row.file}
                        controls
                      />
                    );
                    break;
                  default:
                    mediaIcon = (
                      <img
                        style={{ height: "200px", width: "200px" }}
                        src={catalogo}
                      />
                    );
                    break;
                }
                const labelId = `enhanced-table-checkbox-${row.id}`;

                return (
                  <TableRow key={row.id}>
                    {row.tipologia !== undefined ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{ height: "200px", width: "200px" }}
                          color="primary"
                          checked={isItemSelected(row)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onChange={(event) =>
                            toggleItem(row, event.target.checked)
                          }
                        />
                      </TableCell>
                    ) : hasFiles ? (
                      <TableCell />
                    ) : undefined}

                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        textAlign: "center",
                      }}
                      width={"100"}
                    >
                      {mediaIcon}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span
                        onClick={() => {
                          if (!row.tipologia) {
                            setSelectedFolder(row);
                          } else {
                            visualizzaContenuto(row, true);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {row.nome}
                      </span>
                    </TableCell>
                    {row.downloads.some(
                      (download) => download.email_user === currentUser.email
                    ) ? (
                      <TableCell component="th" scope="row">
                        <Typography
                          onClick={() => {
                            if (!row.tipologia) {
                              setSelectedFolder(row);
                            } else {
                              visualizzaContenuto(row, true);
                            }
                          }}
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Scaricato
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                    <TableCell component="th" scope="row">
                      {row.tipologia ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "0.2%",
                            marginTop: "1%",
                          }}
                        >
                          {currentUser.tipo < 3 && (
                            <CircleButtonComponent
                              icon={<Send />}
                              onClick={() => inviaMessaggio(row)}
                            />
                          )}
                          <CircleButtonComponent
                            icon={<Visibility />}
                            onClick={() => {
                              if (currentUser.tipo < 3) {
                                visualizzaContenuto(row);
                              } else {
                                visualizzaContenuto(row, true);
                              }
                            }}
                          />
                          <CircleButtonComponent
                            text={
                              downloadingQueue.find((i) => i.id === row.id)
                                ? "In download"
                                : ""
                            }
                            disabled={downloadingQueue.find(
                              (i) => i.id === row.id
                            )}
                            icon={<CloudDownload />}
                            onClick={() => downloadFile(row)}
                          />

                          {currentUser.tipo < 3 ? (
                            <>
                              <CircleButtonComponent
                                icon={<AccountTree />}
                                onClick={() => SweetAlertCustomButtons(row)}
                              />
                              <CircleButtonComponent>
                                <Link
                                  onClick={() => {
                                    setItemContenuti(row);
                                  }}
                                  to={() => {
                                    return "contenuti?tab=contenuti&action=modifica";
                                  }}
                                >
                                  <Edit sx={{ color: "white" }} />
                                </Link>
                              </CircleButtonComponent>
                              <CircleButtonComponent
                                icon={<Delete />}
                                onClick={() => {
                                  if (currentUser.tipo < 3) {
                                    Swal.fire({
                                      title: "Confermi?",
                                      html:
                                        "<div style='padding:10px;'><span>" +
                                        row.descrizione +
                                        "</span> Attenzione, il contenuto <b> " +
                                        row.nome +
                                        "</b> sarà eliminato!</div>",
                                      showCancelButton: true,
                                      confirmButtonText: "Elimina",
                                      cancelButtonText: "Annulla",
                                      reverseButtons: false,
                                      icon: "question",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        deleteContent(row);
                                      } else if (
                                        result.dismiss ===
                                        Swal.DismissReason.cancel
                                      ) {
                                        Swal.fire("Operazione annullata");
                                      }
                                    });
                                  }
                                }}
                              />
                            </>
                          ) : undefined}
                        </div>
                      ) : currentUser.tipo < 3 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "0.5%",
                            marginTop: "10%",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setEditFolder(row);
                              setModalShowed({ type: "editFolder" });
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (currentUser.tipo < 3) {
                                Swal.fire({
                                  title: "Confermi?",
                                  html:
                                    "<div style='padding:10px;'><span>" +
                                    row.descrizione +
                                    "</span> Attenzione, la cartella <b> " +
                                    row.nome +
                                    "</b> sarà eliminata!</div>",
                                  showCancelButton: true,
                                  confirmButtonText: "Elimina",
                                  cancelButtonText: "Annulla",
                                  reverseButtons: false,
                                  icon: "question",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    deleteFolder(row);
                                  } else if (
                                    result.dismiss === Swal.DismissReason.cancel
                                  ) {
                                    Swal.fire("Operazione annullata");
                                  }
                                });
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={contents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    ) : (
      <Typography variant="caption" display="block" gutterBottom>
        Nessun contenuto presente al momento
      </Typography>
    );
  };

  const renderBrands = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "32px",
          }}
        >
          <SectionSubtitle text="Gestisci il materiale associato ai brand Euracom. Seleziona un brand dalla lista a scorrimento" />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "1%",
            height: "40%",
            overflowX: "auto",
            padding: "16px 1px",
          }}
        >
          {!isLoading && filteredBrands.length > 0 ? (
            filteredBrands.map((brand) => {
              const { logo } = brand;
              return (
                <Card
                  elevation={2}
                  sx={{
                    width: "180px",
                    height: "200px",
                    display: "flex",
                    flexShrink: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <CardActionArea
                    style={{
                      height: "100%",
                      width: "100%",
                      alignContent: "center",
                      alignSelf: "center",
                      padding: "0 5px",
                    }}
                    onClick={() => {
                      setSelectedBrand(brand);
                      setSelectedFolder(undefined);
                      setPage(0);
                    }}
                  >
                    <CardMedia
                      component="img"
                      onClick={scrollToContent}
                      image={process.env.REACT_APP_BASE_URL_CONTENTS + logo}
                      alt=""
                      style={{
                        display: "block",
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        alignSelf: "center",
                      }}
                    />
                  </CardActionArea>
                  {selectedBrand && selectedBrand.id == brand.id && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          height: "5px",
                          width: "100%",
                          backgroundColor: "red",
                        }}
                      />
                    </>
                  )}
                </Card>
              );
            })
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </>
    );
  };

  const renderActionSection = () => {
    return (
      <>
        <Divider />
        {currentUser.tipo < 3 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "0.5%",
              marginTop: "1%",
            }}
          >
            {!selectedFolder && (
              <CircleButtonComponent
                icon={<CreateNewFolder />}
                text="Nuova cartella"
                onClick={() => setModalShowed({ type: "newFolder" })}
              />
            )}
            <CircleButtonComponent
              icon={<AutoFixHigh />}
              text="Nuovi contenuti"
              onClick={() => {
                let path = "contenuti?tab=contenuti&action=wizard";
                if (selectedFolder) {
                  path = `${path}&folder=${selectedFolder.id}`;
                }
                if (selectedBrand) {
                  path = `${path}&brand=${selectedBrand.id}`;
                }
                history.push(path);
              }}
            />
          </div>
        ) : null}
      </>
    );
  };

  const SweetAlertCustomButtons = (item) => {
    swal({
      title: "Gestione associazioni",
      text:
        "gestisci le associazioni ai brands, alle categorie e ai tags di " +
        item.nome,
      icon: "info",
      showConfirmButton: false,
      showCancelButton: false,
      buttons: {
        buttonOne: {
          text: "Brands",
          value: "brands",
          visible: true,
          className: "customSwalBtn",
          closeModal: true,
        },
        buttonTwo: {
          text: "Categorie",
          value: "categorie",
          visible: true,
          className: "customSwalBtn",
          closeModal: true,
        },
        buttonThree: {
          text: "Tags",
          value: "tags",
          visible: true,
          className: "customSwalBtn",
          closeModal: true,
        },
        buttonFour: {
          text: "Chiudi",
          value: "chiudi",
          visible: true,
          className: "customSwalBtnClose",
          closeModal: true,
        },
      },
    }).then((result) => {
      setItemContenuti(item);
      if (result === "brands") {
        history.push("contenuti?tab=contenuti&action=associazione-brands");
      } else if (result === "categorie") {
        history.push("contenuti?tab=contenuti&action=associazione-categorie");
      } else if (result === "tags") {
        history.push("contenuti?tab=contenuti&action=associazione-tags");
      }
    });
  };

  return (
    <div>
      <Modal
        open={
          modalShowed.type == "newFolder" || modalShowed.type == "editFolder"
        }
        onClose={handleClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <NuovaCartella
            handleClose={handleClose}
            id_brand={selectedBrand && selectedBrand.id}
            folder={editFolder}
            refreshDatas={refreshDatas}
          />
        </Box>
      </Modal>

      <Modal
        disableEnforceFocus={true}
        disableAutoFocus={true}
        open={modalShowed.type == "newMessage"}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Messaggio
            onCloseModal={handleClose}
            message={messageData}
            title={titleComponent}
          />
        </Box>
      </Modal>

      {renderBrands()}
      {selectedBrand && renderActionSection()}
      {selectedBrand && renderItemsTable()}
    </div>
  );
}
export default Cartelle;
