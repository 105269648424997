import { React, useState, useEffect, useRef } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { API_KEY } from "../../CONST";

export function Messaggio(props) {
  const [message] = useState(props.message);
  const [title] = useState(props.title);
  function onModalClose() {
    props.onCloseModal();
  }
  const [usersEmail, setUsersEmails] = useState([]);
  const [categories, setCategories] = useState([]);
  const [usersFromCats, setUsersFromCategories] = useState([]);
  const [sendToGroup, setSendToGroup] = useState(false);
  const emails = [];
  const userData = localStorage.getItem("userDetails", "undefined");
  const user = JSON.parse(userData);
  const refCatOption = useRef();
  console.log(props);
  const refDesOption = useRef();

  useEffect(() => {
    getUsers();
    getCategories();
  }, []);

  const getUsers = () => {
    let currentUser = JSON.parse(userData);
    console.log("fetching users mail");
    fetch(process.env.REACT_APP_BASE_URL + "email-utenti/" + user.tipo)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (Array.isArray(res) && res.length > 0) {
          let itemCurrent = res.find((u) => u.value === currentUser.email);
          if (itemCurrent) {
            const index = res.indexOf(itemCurrent);
            if (index > -1) res.splice(index, 1);
          }
          if (props.user && props.user.tipo > 2) {
            setUsersEmails([
              {
                label: "Marketing Account",
                tipo: 1,
                value: "marketing@euracom.it",
              },
            ]);
          } else {
            setUsersEmails(res);
          }
        } else {
          throw new Error();
        }
      })
      .catch(function (error) {
        setUsersEmails([]);
      });
  };

  const getCategories = () => {
    fetch(process.env.REACT_APP_BASE_URL + "email-categorie")
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          setCategories(res);
        } else {
          throw new Error();
        }
      })
      .catch(function (error) {
        setCategories([]);
      });
  };

  const getUsersFromCat = (idcat) => {
    fetch(process.env.REACT_APP_BASE_URL + "emails-from-categorie/" + idcat)
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          setUsersFromCategories(res);
        } else {
          throw new Error();
        }
      })
      .catch(function (error) {
        setUsersFromCategories([]);
      });
  };

  const handleChangeDest = (e) => {
    refCatOption.current.hidden = e.target.checked;
    refDesOption.current.hidden = !e.target.checked;
    setSendToGroup(false);
  };

  const handleChangeGroup = (e) => {
    refCatOption.current.hidden = !e.target.checked;
    refDesOption.current.hidden = e.target.checked;
    setSendToGroup(true);
  };

  const handleChangeCat = (e) => {
    getUsersFromCat(e.value);
  };

  const saveBody = (content) => {
    message.body = content;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.destinatari.value === "groups") {
      if (event.target.groups.value === "") {
        Swal.fire({ title: "", text: "Errore!", icon: "error" });
      }
    }
    const elements = event.target.elements;
    const dataMail = {};
    const data = {};

    for (let i = 0; i < elements.length; i++) {
      const elem = elements[i];

      if (elem.name !== "users" && elem.name !== "priority") {
        dataMail[elem.name] = elem.value;
      } else if (elem.name === "priority") {
        dataMail[elem.name] = elem.checked ? 1 : 0;
      } else if (elem.name === "users") {
        if (!props.message.isresponse) {
          if (!sendToGroup) {
            let userData = usersEmail.find((u) => {
              return u.value === elem.value;
            });
            if (userData) {
              emails.push({ email: elem.value, nominativo: userData.label });
            }
          }
        }
      }
    }

    dataMail["body"] = message.body;
    let currentUser = JSON.parse(userData);
    dataMail["sendername"] = currentUser.nome + " " + currentUser.cognome;
    dataMail["sendermail"] = currentUser.email;

    if (sendToGroup) {
      usersFromCats.forEach((i) => {
        emails.push(i);
      });
      if (emails.length === 0) {
        let cat = categories.find((c) => {
          return c.value === parseInt(event.target.groups.value);
        });
        if (cat) {
          Swal.fire({
            title: "",
            text: "La categoria " + cat.label + " non contiene contatti! ",
            icon: "warning",
          });
        }
        return;
      }
    } else {
      if (emails.length === 0) {
        if (!props.message.isresponse) {
          Swal.fire({
            title: "",
            text: "Nessun contatto selezionato ",
            icon: "warning",
          });
          return;
        } else {
          emails.push({ email: message.toaddress, nominativo: message.to });
        }
      }
    }

    emails.forEach((e) => (e["data"] = dataMail));
    data["emails"] = emails;
    data["issharingcontent"] = message.issharing;

    axios
      .post(process.env.REACT_APP_BASE_URL + "send-email", data)
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Messaggio inviato ai destinatari selezionati",
            icon: "success",
          });
          onModalClose();
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        Swal.fire({ title: "", text: "Errore!", icon: "error" });
      });
  };

  if (!usersEmail) {
    return <div className="App">Loading...</div>;
  }
  return (
    <>
      <div className="dettagli-messaggio">
        <form className="messages" onSubmit={handleSubmit}>
          <h3>{title}</h3>
          <label>da</label>
          <input type="text" value={user.email} disabled name="sender" />
          <br />

          <label hidden={message.isresponse}>destinatari</label>
          <input
            hidden={message.isresponse}
            type="radio"
            name="destinatari"
            defaultChecked={true}
            value="destinatari"
            onClick={handleChangeDest}
          />
          <label
            hidden={message.isresponse || (props.user && props.user.tipo > 2)}
          >
            categorie
          </label>
          <input
            hidden={message.isresponse || (props.user && props.user.tipo > 2)}
            type="radio"
            name="destinatari"
            defaultChecked={false}
            value="gruppo"
            onClick={handleChangeGroup}
          />
          <div ref={refDesOption} hidden={message.isresponse}>
            <Select
              className="opzioni"
              name="users"
              placeholder="Seleziona destinatari"
              isMulti
              options={usersEmail}
              required
            />
            <br />
          </div>

          <br />

          <div ref={refCatOption} hidden={true}>
            <Select
              className="opzioni"
              name="groups"
              placeholder="Seleziona gruppo"
              onChange={handleChangeCat}
              options={categories}
              required
            />
            <br />
          </div>
          <label>oggetto</label>
          <input
            type="text"
            name="subject"
            defaultValue={message.subject}
            required
          />
          <br />
          <label>messaggio</label>

          <Editor
            apiKey={API_KEY}
            style={{ zIndex: "999" }}
            initialValue={message.body}
            init={{
              relative_urls: false,
              remove_script_host: false,
              menubar: "insert",
              statusbar: true,
              resize: true,
              height: 200,
              min_height: 200,
              language: "it",
              plugins: [
                "advlist autolink autoresize lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | removeformat | code | help",
            }}
            onEditorChange={saveBody}
          />
          <br />
          <label>priorit&agrave; alta</label>
          <input name="priority" type="checkbox" />
          <br />
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button
              className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
              type="submit"
            >
              Invia
            </Button>
            <Button
              className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
              type="button"
              onClick={onModalClose}
            >
              Annulla{" "}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default Messaggio;
