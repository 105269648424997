import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useLocation } from "react-router-dom";
import MetisMenu from "metismenujs";
import { Typography } from "antd";
import {
  CalendarViewMonth,
  FolderCopy,
  SmartDisplay,
} from "@mui/icons-material";
import { THEME_RED } from "../../../CONST";

const MENU_ITEMS = {
  player: ["player"],
  contenuti: ["contenuti"],
  palinsesti: ["palinsesti"],
};

const MM = ({ children }) => {
  useEffect(() => {
    const metismenu = new MetisMenu(".metismenu");
    return () => {
      metismenu.dispose();
    };
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu" style={{ display: "flex" }}>
        {children}
      </ul>
    </div>
  );
};

const SideBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const btn = document.querySelector(".menu-button");
    const navbar = document.querySelector(".dlabnav");
    const mainWrapper = document.querySelector("#main-wrapper");

    if (!btn || !navbar || !mainWrapper) return;

    const toggleFunc = () => {
      navbar.style.display = mainWrapper.classList.contains("menu-toggle")
        ? "block"
        : "none";
      mainWrapper.classList.toggle("menu-toggle");
    };

    btn.addEventListener("click", toggleFunc);
    return () => {
      btn.removeEventListener("click", toggleFunc);
    };
  }, []);

  useEffect(() => {
    const mainWrapper = document.querySelector("#main-wrapper");
    if (mainWrapper) {
      mainWrapper.classList.remove("menu-toggle");
    }
  }, [location]);

  const path = location.pathname.split("/").pop();

  return (
    <div className="dlabnav">
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li
            className={`${MENU_ITEMS.player.includes(path) ? "mm-active" : ""}`}
          >
            <Link
              className={`${path === "player" ? "mm-active" : ""}`}
              to="/player"
              style={{ display: "flex", alignItems: "center" }}
            >
              <SmartDisplay sx={{ color: `${THEME_RED} !important` }} />
              <Typography>TV & Licenze</Typography>
            </Link>
          </li>
          <li
            className={`${
              MENU_ITEMS.contenuti.includes(path) ? "mm-active" : ""
            }`}
          >
            <Link
              className={`${path === "contenuti" ? "mm-active" : ""}`}
              to="/contenuti?tab=cartelle"
              style={{ display: "flex", alignItems: "center" }}
            >
              <FolderCopy sx={{ color: `${THEME_RED} !important` }} />
              <Typography>Contenuti</Typography>
            </Link>
          </li>
          <li
            className={`${
              MENU_ITEMS.palinsesti.includes(path) ? "mm-active" : ""
            }`}
          >
            <Link
              className={`${path === "palinsesti" ? "mm-active" : ""}`}
              to="/palinsesti?tab=palinsesti-sub"
              style={{ display: "flex", alignItems: "center" }}
            >
              <CalendarViewMonth sx={{ color: `${THEME_RED} !important` }} />
              <Typography>Palinsesti</Typography>
            </Link>
          </li>
        </MM>
      </PerfectScrollbar>

      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="InstructionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="favoritesModalLabel"
        style={{
          display: isModalOpen ? "block" : "none",
          marginTop: "8vh",
          marginBottom: "8vh",
          height: "84vh",
        }}
      ></div>
    </div>
  );
};

export default SideBar;
