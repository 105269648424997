import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import dateFormat from "../functions/dateFormat";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import PalinsestoPreview from "./palinsesto-preview";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Add, Delete, Edit, LiveTv, Refresh } from "@mui/icons-material";
import { Typography } from "@mui/material";
export let itemPalinsesto = [];
function PalinsestiSub() {
  const [show, setShow] = useState(false);
  let templates = [
    "Orizzontale",
    "Verticale",
    "Evidenza Sinistro",
    "Evidenza Destro",
    "Evidenza Alto",
    "Evidenza Basso",
  ];
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  console.log(currentUser);
  const [palinsestoCorrente, setPalinsestoCorrente] = useState({});
  const [palinsestiShow, setPalinsestiShow] = useState([]);
  const [palinsesti, setPalinsesti] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getPalinsesti = () => {
    let path = "palinsesti";
    if (currentUser.tipo > 2) {
      path = `palinsesti/${currentUser.userid}`;
    }

    fetch(process.env.REACT_APP_BASE_URL + path)
      .then((res) => res.json())
      .then((res) => {
        setPalinsesti([]);
        if (res.length > 0) {
          setPalinsesti(res);
        } else {
          setPalinsesti([{ uuid: "0", nome: "Nessun palinsesto presente!" }]);
        }
      });
  };
  useEffect(() => {
    getPalinsesti();
  }, []);
  useEffect(() => {
    let palinsestiData = [];
    palinsesti.map((item) => {
      if (item.data_creazione) {
        item.datacreazione = dateFormat(new Date(item.data_creazione));
      }
      item.templatenome = templates[item.template];
      item.action = (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "120px",
          }}
        >
          {currentUser.tipo <= 2 && (
            <CircleButtonComponent
              icon={<Refresh />}
              onClick={() => buildSchedule(item)}
            />
          )}
          <CircleButtonComponent
            icon={<LiveTv />}
            onClick={() => {
              setPalinsestoCorrente(item);
              handleShow();
            }}
          />
          {currentUser.tipo < 3 && (
            <CircleButtonComponent>
              <Link
                to={() => {
                  itemPalinsesto = item;
                  return "palinsesti?tab=palinsesti-sub&action=modifica";
                }}
              >
                <Edit sx={{ color: "white" }} />
              </Link>
            </CircleButtonComponent>
          )}

          {currentUser.tipo <= 2 && (
            <CircleButtonComponent
              icon={<Delete />}
              onClick={() => clickDeleteButton(item)}
            />
          )}
        </div>
      );
      palinsestiData.push(item);
    });
    setPalinsestiShow(palinsestiData);
  }, [palinsesti]);
  const buildSchedule = (item) => {
    const date = new Date();
    fetch(process.env.REACT_APP_BASE_URL + `autoplan/${item.uuid}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        if (response.status === 200) {
          response.text().then(function (text) {
            let result = JSON.parse(text);
            let icon = result.status === 200 ? "success" : "warning";
            Swal.fire({
              title:
                "Le modifiche apportate al palinsesto sono state applicate",
              text: result.result,
              icon: icon,
            });
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Impossibile rigenerare il palinsesto selezionato",
          icon: "error",
        });
      });
  };
  const deletePalinsesto = (item) => {
    const dataDel = {};
    dataDel["id"] = item.uuid;
    fetch(process.env.REACT_APP_BASE_URL + "elimina-palinsesto", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(function () {
            getPalinsesti();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare il palinsesto selezionato",
          icon: "error",
        });
      });
  };
  const clickDeleteButton = (item) => {
    Swal.fire({
      title: "Confermi?",
      html:
        "Attenzione, il palinsesto <b> " +
        item.nome +
        "</b> sarà eliminato!</div>",
      showCancelButton: true,
      confirmButtonText: "Elimina",
      cancelButtonText: "Annulla",
      reverseButtons: true,
      icon: "question",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePalinsesto(item);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({ title: "", text: "Operazione annullata", icon: "error" });
      }
    });
  };
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: 270,
      },
      {
        label: "Orientamento",
        field: "templatenome",
        sort: "asc",
        width: 200,
      },
      {
        label: "Azione",
        field: "action",
        sort: "disabled",
        width: 40,
      },
    ],
    rows: palinsestiShow,
  };

  return (
    <div className="tabella_palinsesti">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <Typography variant="h6">Gestisci i tuoi palinsesti</Typography>

        {currentUser.tipo <= 2 && (
          <CircleButtonComponent
            icon={<Add />}
            text="Aggiungi"
            onClick={() =>
              (window.location.href =
                "palinsesti?tab=palinsesti-sub&action=aggiunta")
            }
          />
        )}
      </div>

      <MDBDataTableV5
        hover
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        searchLabel={"Cerca"}
        responsive
        disableRetreatAfterSorting={true}
      />
      <Modal
        dialogClassName="modal-messages"
        enforceFocus={false}
        show={show}
        onRequestClose={handleClose}
      >
        <Modal.Body>
          <Button
            style={{ float: "right" }}
            variant="link"
            onClick={handleClose}
          >
            <i style={{ fontSize: "120%" }} className="bi bi-x"></i>
          </Button>
          <PalinsestoPreview palinsesto={palinsestoCorrente} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PalinsestiSub;
