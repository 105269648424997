import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
//import { v4 as uuidv4 } from 'uuid';
//import vuoto from "../../images/vuoto.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import { itemContenuti } from "./contenuti";
import catalogo from "../../images/catalogo.png";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import moment from "moment";
function ModificaContenuto() {
  registerLocale("it", it);
  const extensionsAudio = ".mp3,.wav,.aac,.mp2,.m4a,.wma,.ogg,.ac3";
  const extensionsVideo = ".mp4,.m4v,.mpeg,.ts";
  const extensionsDocs = ".pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx";
  //Non rimuovere questo commento
  //const extensionsUnable = "wmv|avi|mkv|ps,.tif,.tiff"
  //------------------------>
  const extensionsImages = ".png,.jpeg,.jpg,.bmp,.gif";
  const mediaRef = useRef();
  const imageRef = useRef();
  //const [file, setFile] = useState('');
  const [startDis, setStartDis] = useState(false);
  const [endDis, setEndDis] = useState(false);
  //const [tipo, setTipo] = useState("Nessuno");
  const history = useHistory();
  const [uploadValue, setUploadValue] = useState(0);
  const [disabledSend, setDisabledSend] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(itemContenuti.data_inizio_pubblicazione)
  );
  const [endDate, setEndDate] = useState(
    new Date(itemContenuti.data_fine_pubblicazione)
  );
  const config = {
    onUploadProgress: (progressEvent) => {
      try {
        setDisabledSend(true);
        setUploadValue(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      } catch (error) {}
    },
  };
  /* eslint-disable */
  useEffect(() => {
    if (!itemContenuti) {
      history.push("contenuti?tab=tutti-i-contenuti");
    }
  }, []);
  useEffect(() => {
    setPreviewOnLoad();
  }, []);

  useState(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);
  const handleChange = (e) => {
    try {
      const file = e.target.files[0];
      if (file.name) {
        setPreview(file.name, file);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "",
        text: "Tipo di file non riconosciuto o non consentito!",
        icon: "error",
      });
      e.target.value = null;
    }
  };
  const setPreviewOnLoad = () => {
    try {
      let previewVideo = mediaRef.current;
      let previewImage = imageRef.current;
      let durata = document.getElementById("durata");
      let ext = itemContenuti.file.split(".").pop().toLowerCase();
      if (extensionsImages.includes(ext)) {
        previewImage.src =
          process.env.REACT_APP_BASE_URL_CONTENTS + itemContenuti.file;

        previewVideo.hidden = true;
        previewImage.hidden = false;
        //setTipo("Immagine");
      } else if (extensionsVideo.includes(ext)) {
        previewVideo.src =
          process.env.REACT_APP_BASE_URL_CONTENTS + itemContenuti.file;
        previewVideo.preload = "metadata";
        previewVideo.addEventListener("loadedmetadata", function () {
          durata.value = Math.floor(previewVideo.duration);
        });
        previewVideo.hidden = false;
        previewImage.hidden = true;
        //setTipo("Video");
      } else if (extensionsAudio.includes(ext)) {
        previewVideo.src =
          process.env.REACT_APP_BASE_URL_CONTENTS + itemContenuti.file;
        previewVideo.poster = "";
        previewVideo.preload = "metadata";
        previewVideo.addEventListener("loadedmetadata", function () {
          durata.value = Math.floor(previewVideo.duration);
        });
        previewVideo.hidden = false;
        previewImage.hidden = true;
        //setTipo("Audio");
      } else if (extensionsDocs.includes(ext)) {
        previewImage.src = catalogo;
        previewVideo.hidden = true;
        previewImage.hidden = false;
        //setTipo("Catalogo");
      } else {
        if (ext !== "") {
          Swal.fire({
            title: "",
            text: "Tipo di file non consentito!",
            icon: "error",
          });
        }
      }
    } catch (error) {}
  };
  const setPreview = (filename, file) => {
    let previewVideo = mediaRef.current;
    let previewImage = imageRef.current;

    let durata = document.getElementById("durata");
    let ext = filename.split(".").pop().toLowerCase();
    if (extensionsImages.includes(ext)) {
      previewImage.src = URL.createObjectURL(file);
      durata.value = 30;
      previewVideo.hidden = true;
      previewImage.hidden = false;
    } else if (extensionsVideo.includes(ext)) {
      previewVideo.src = URL.createObjectURL(file);
      previewVideo.preload = "metadata";
      previewVideo.addEventListener("loadedmetadata", function () {
        durata.value = Math.floor(previewVideo.duration);
      });
      previewVideo.hidden = false;
      previewImage.hidden = true;
    } else if (extensionsAudio.includes(ext)) {
      previewVideo.src = URL.createObjectURL(file);
      previewVideo.poster = "";
      previewVideo.preload = "metadata";
      previewVideo.addEventListener("loadedmetadata", function () {
        durata.value = Math.floor(previewVideo.duration);
      });
      previewVideo.hidden = false;
      previewImage.hidden = true;
    } else if (extensionsDocs.includes(ext)) {
      previewImage.src = catalogo;
      previewVideo.hidden = true;
      previewImage.hidden = false;
    } else {
      Swal.fire({
        title: "",
        text: "Tipo di file non consentito!",
        icon: "error",
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setUploadValue(0);
    const form = event.target;
    let fileup = null;
    if (event.target.up_file.files.length > 0) {
      fileup = event.target.up_file.files[0];
    }
    const data = new FormData();
    for (let i = 0; i < form.elements.length; i++) {
      const elem = form.elements[i];
      if (elem.name === "published") {
        data.append(elem.name, elem.checked ? 1 : 0);
      } else if (elem.name === "has_start") {
        data.append(elem.name, elem.checked ? 1 : 0);
      } else if (elem.name === "has_end") {
        data.append(elem.name, elem.checked ? 1 : 0);
      } else if (elem.name === "start_date") {
        data.append(elem.name, moment(startDate).format("YYYY-MM-DDTHH:mm:ss"));
      } else if (elem.name === "end_date") {
        data.append(elem.name, moment(endDate).format("YYYY-MM-DDTHH:mm:ss"));
      } else {
        data.append(elem.name, elem.value);
      }
    }
    if (fileup) {
      let fname = itemContenuti.file;
      data.append("file", fileup, fname);
    } else {
      data.append("file", itemContenuti.file);
    }
    axios
      .post(
        process.env.REACT_APP_BASE_URL + "modifica-contenuto",
        data,
        config,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          setDisabledSend(false);
          Swal.fire({
            title: "",
            text: "Contenuto caricato con successo",
            icon: "success",
          });
          history.push("contenuti?tab=cartelle");
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        console.log(error);
        try {
          setDisabledSend(false);
          if (axios.isCancel(error)) {
            Swal.fire({
              title: "",
              text: "Caricamento non completato!",
              icon: "warning",
            });
          } else {
            Swal.fire({ title: "", text: "Errore caricamento", icon: "error" });
          }
        } catch (err) {}
      });
  };

  return (
    <>
      <div className="card">
        <p className="pform">Modifica Contenuto</p>
        <div className="card-bodyx">
          <form
            controlId="formContenuto"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="form-row">
              <label className="form-label">ANTEPRIMA</label>
              <video
                height="180px"
                style={{ maxHeight: "180px", minHeight: "180px" }}
                ref={mediaRef}
                hidden={true}
                controls
                alt=""
              />
              &nbsp;&nbsp;&nbsp;
              <img
                height="180px"
                style={{ maxHeight: "180px", minHeight: "180px" }}
                ref={imageRef}
                hidden={true}
                alt=""
              />
            </div>
            <div hidden={disabledSend} className="form-row">
              <label className="form-label">FILE</label>
              <input
                className="form-input"
                ACCEPT={
                  extensionsVideo +
                  "," +
                  extensionsAudio +
                  "," +
                  extensionsImages +
                  "," +
                  extensionsDocs
                }
                type="file"
                name="up_file"
                onChange={handleChange}
              />
            </div>
            <div hidden={!disabledSend} className="form-row">
              <label className="form-label">UPLOAD %</label>
              <div className="form-input">
                <ProgressBar animated now={uploadValue} />
              </div>
            </div>
            <div hidden={true} className="form-row">
              <input
                className="form-input"
                type="hidden"
                defaultValue={itemContenuti.id}
                name="id"
              />
              <input
                type="hidden"
                name="tipologia"
                defaultValue={itemContenuti.tipologia}
              />
              <input
                className="form-input"
                type="hidden"
                defaultValue={itemContenuti.dimensione}
                name="dimensione"
              />
              <input
                className="form-input"
                type="hidden"
                defaultValue={itemContenuti.id_cartella}
                name="idcartella"
              />
            </div>

            <div className="form-row">
              <label className="form-label">TITOLO</label>
              <input
                className="form-input"
                type="text"
                defaultValue={itemContenuti.nome}
                placeholder="Inserire il titolo del contenuto"
                name="nome"
                required
              />
            </div>
            <div className="form-row">
              <label className="form-label">DESCRIZIONE</label>
              <input
                className="form-input"
                defaultValue={itemContenuti.descrizione}
                type="text"
                placeholder="Inserire la descrizione del contenuto"
                name="descrizione"
                required
              />
            </div>
            <div className="form-row">
              <label className="form-label">PUBBLICATO</label>
              <div className="form-input">
                <input
                  name="published"
                  type="checkbox"
                  defaultChecked={itemContenuti.pubblicato === 0 ? false : true}
                />
              </div>{" "}
            </div>
            <div className="form-row">
              <label className="form-label">VALIDIT&Agrave;</label>

              <div className="form-select-option-data">
                <div style={{ display: "table" }}>
                  <div style={{ display: "table-row" }}>
                    <div style={{ display: "table-cell" }}>
                      <span>DA</span>
                    </div>
                    <input
                      name="has_start"
                      onChange={(e) => {
                        setStartDis(!e.currentTarget.checked);
                      }}
                      type="checkbox"
                      defaultChecked={itemContenuti.inizio === 0 ? false : true}
                    />
                    <div style={{ display: "table-cell" }}>
                      <DatePicker
                        disabled={startDis}
                        name="start_date"
                        dateFormat="dd/MM/yyyy"
                        locale="it"
                        selected={startDate}
                        //showTimeSelect
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        //minDate={startDate}
                      />
                    </div>
                    <div style={{ display: "table-cell" }}>
                      <span className="s1">A</span>
                    </div>
                    <input
                      name="has_end"
                      onChange={(e) => {
                        setEndDis(!e.currentTarget.checked);
                      }}
                      type="checkbox"
                      defaultChecked={itemContenuti.fine === 0 ? false : true}
                    />
                    <div style={{ display: "table-cell" }}></div>
                    <DatePicker
                      disabled={endDis}
                      name="end_date"
                      dateFormat="dd/MM/yyyy"
                      locale="it"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      // minDate={startDate}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <label className="form-label">DURATA (Secondi)</label>
              <input
                className="form-input"
                defaultValue={itemContenuti.durata}
                type="text"
                name="durata"
                id="durata"
                required
                disabled
              />
            </div>

            <div className="form-row">
              <div className="form-buttons">
                <Button
                  disabled={disabledSend}
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="submit"
                >
                  Invia
                </Button>
                <Button
                  disabled={disabledSend}
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="button"
                  onClick={() => {
                    history.push("contenuti?tab=cartelle");
                  }}
                >
                  Annulla{" "}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ModificaContenuto;
